import React, { useEffect, useState } from "react"
import Header from "../header"
import { Outlet } from "react-router-dom"
import { Container } from "@mui/material"
import { useFetch } from "../../../hooks/useFetch"
import { queryKey } from "../../../constant/queryKey"
import HashchingPropertyLoader from "../../hashingPropertyLoader"
import UIModal from "../../reusable/UIModal"
import { useDispatch, useSelector } from "react-redux"
import { setConfirmationModal } from "../../../redux/globalState"
import rightIcon from "../../../images/right-icon.png"
import wrongIcon from "../../../images/wrong-icon.png"
import ScrollToTop from "./scrollToTop"
import Footer from "../footer"
import { useProperty } from "../useLayout/useProperty"

const Main = () => {
  const { key, url } = queryKey.getProperties
  const [headerHeight, setHeaderHeight] = useState(0)
  const dispatch = useDispatch()
  const scrollRef = React.useRef(null)
  const confirmationModal = useSelector((state) => state.globalState.confirmationModal)

  const { isLoading } = useFetch(
    key,
    url,
    () => {},
    () => {},
    true,
    (res) => res?.data
  )

  const handeGlobalModalClose = () => {
    dispatch(setConfirmationModal({ ...confirmationModal, open: false, children: null }))
  }

  const { EnquiryModal } = useProperty()

  useEffect(() => {
    const headerElement = document.getElementById("property-header") // Replace with your actual header ID
    if (headerElement) {
      const height = headerElement.clientHeight
      setHeaderHeight(height)
    }
  }, [isLoading])

  return (
    <div>
      <Header />

      <EnquiryModal />

      <UIModal
        open={confirmationModal.open}
        onClose={handeGlobalModalClose}
        size={confirmationModal.size}
        header={confirmationModal.header}
        footer={confirmationModal.footer}
        closeButton={true}
        vPosition={confirmationModal.vPosition}
      >
        {confirmationModal.type === "success" && (
          <SuccessConfirmation message={confirmationModal.message} />
        )}

        {confirmationModal.type === "error" && (
          <ErrorConfirmation message={confirmationModal.message} />
        )}
      </UIModal>
      <div ref={scrollRef} style={{ overflow: "auto", height: `calc(100vh - ${headerHeight}px)` }}>
        <ScrollToTop scrollRef={scrollRef} />

        <Container style={{ marginBottom: "50px" }} className='p-4'>
          <Outlet />
          {isLoading && <HashchingPropertyLoader />}
        </Container>
      </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Main

const SuccessConfirmation = ({ message }) => {
  return (
    <div className='flex flex-col gap-1 justify-center items-center p-4'>
      <img className='w-24' alt='success' src={rightIcon} />
      <span className='text-lg font-semibold'>{message}</span>
    </div>
  )
}
const ErrorConfirmation = ({ message }) => {
  return (
    <div className='flex flex-col gap-1 justify-center items-center p-4'>
      <img className='w-24' alt='success' src={wrongIcon} />
      <span className='text-lg font-semibold'>{message}</span>
    </div>
  )
}
