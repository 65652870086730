import { AppBar } from "@mui/material"
import logoIcon from "../../../images/logo.png"
import React from "react"
import UIButton from "../../reusable/UIButton"
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import logo from '../../../images/webauctrn_logo.svg'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const handleEnquiryOpen = () => {
    dispatch({ type: "globalState/setShowEnquiryModal", payload: true })
  }

  const handleLogoClick = () => {
    if (pathname === "/") {
      // refresh the page
      window.location.reload()
    }
    navigate("/")
  }

  return (
    <AppBar
      sx={{
        paddingY: "4px",
        background: "white",
        position: "sticky",
        top: "0",
      }}
      id='property-header'
    >
      <div className='flex justify-between items-center px-2 sm:px-4 md:px-10 pt-3 pb-5'>
        <div className='relative cursor-pointer pr-1' onClick={handleLogoClick}>
          <img alt='logo' src={logo} className='object-cover min-h-[25px] max-h-[40px]' />
        </div>
        <div className='pl-1 flex flex-wrap items-center justify-end gap-1 md:gap-3 translate-y-1'>
          <UIButton onClick={handleEnquiryOpen}>ENQUIRE NOW</UIButton>
          <a href='tel:1800-444-744' className='text-primary font-bold'>
            1800 444 744
          </a>
        </div>
      </div>
    </AppBar>
  )
}

export default Header
