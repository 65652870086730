import { FormControl, IconButton, Slider } from "@mui/material"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { setPackagePercent, setPropertyFilter } from "../../../redux/globalState"
import { queryKey } from "../../../constant/queryKey"
import { useFetch } from "../../../hooks/useFetch"
import Select from "react-select"
import { primaryColor } from "../../../constant"
import ClearIcon from "@mui/icons-material/Clear"
import { useIsFetched } from "../../../hooks/useIsFetched"

const minDistance = 10

const bedroomOptions = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4+",
    label: "4+",
  },
]

const categoryOptions = [
  {
    label: "House and Land",
    value: "House and Land",
  },
  {
    label: "Completed Homes",
    value: "Completed Homes",
  },
  {
    label: "House and Granny Flat",
    value: "House and Granny Flat",
  },
  {
    label: "Duplex",
    value: "Duplex",
  },
  {
    label: "Other",
    value: "Other",
  },
]
export const usePropertyFilter = () => {
  const dispatch = useDispatch()
  const [locationOptions, setLocationOptions] = React.useState([])
  const [inputValue, setInputValue] = React.useState("")

  const handleLocationInputChange = (input) => {
    setInputValue(input)
  }

  const isInputEmpty = inputValue.trim() === ""

  const propertyFilter = useSelector((state) => state.globalState.propertyFilter)

  const { key, url } = queryKey.getProperties

  const { data: properties } = useFetch(
    key,
    url,
    () => {},
    () => {},
    false,
    (res) => res?.data
  )

  const packagePercent = useSelector((state) => state.globalState.packagePercent)

  const handlePackagePrice = (_event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance)
        dispatch(setPackagePercent([clamped, clamped + minDistance]))
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        dispatch(setPackagePercent([clamped - minDistance, clamped]))
      }
    } else {
      dispatch(setPackagePercent(newValue))
    }

    if (properties) {
      const allPackagePrice = properties.map((property) => parseInt(property?.packageprice))
      const maxPrize = Math.max(...allPackagePrice)

      const minPackagePrice = (newValue[0] * maxPrize) / 100

      const maxPackagePrice = (newValue[1] * maxPrize) / 100

      dispatch(
        setPropertyFilter({
          ...propertyFilter,
          packagePrice: {
            min: minPackagePrice,
            max: maxPackagePrice,
          },
        })
      )
    }
  }

  const { location, noOfBedrooms, category } = propertyFilter

  const { key: locationKey, url: locationUrl } = queryKey.getLocations

  const handleLocationFilter = (event) => {
    if (event === null) {
      dispatch(setPropertyFilter({ ...propertyFilter, location: "" }))
    } else {
      dispatch(setPropertyFilter({ ...propertyFilter, location: event.value }))
    }
  }

  const isLocationsFetched = useIsFetched(locationKey)

  const { data: locations } = useFetch(
    locationKey,
    locationUrl,
    () => {},
    () => {},
    !isLocationsFetched,
    (res) => res?.data
  )

  React.useEffect(() => {
    if (locations) {
      const locationOptions = locations?.map((location) => ({
        label: location?.postcode + ", " + location?.suburb + ", " + location?.state,
        value: location?.suburbId,
      }))
      setLocationOptions(locationOptions)
    }
  }, [locations])

  const handleNumberOfBedRooms = (event) => {
    if (event === null) {
      dispatch(setPropertyFilter({ ...propertyFilter, noOfBedrooms: "" }))
    } else {
      dispatch(
        setPropertyFilter({
          ...propertyFilter,
          noOfBedrooms: event.value,
        })
      )
    }
  }

  const handleCategoryFilter = (event) => {
    if (event === null) {
      dispatch(setPropertyFilter({ ...propertyFilter, category: "" }))
    } else {
      dispatch(setPropertyFilter({ ...propertyFilter, category: event.value }))
    }
  }

  const propertyFilterJSX = (
    <div>
      <div className='grid sm:grid-cols-7 mb-4 gap-x-4 gap-y-2 place-content-center'>
        {/* <div className='flex justify-center items-center gap-1 mr-2'> */}
        {/* <TextField
          id='property'
          label='Search Property'
          value={propertName}
          onChange={handlePropertyNameSearch}
          variant='outlined'
        /> */}
        {/* <Button
          className='h-14'
          variant='contained'
          sx={{
            bgcolor: primaryColor,
            "&:hover": {
              bgcolor: primaryColor,
            },
          }}
        >
          Search
        </Button> */}
        {/* </div> */}

        {/* location */}
        <div className='sm:col-span-3 flex-grow'>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <Select
              id='location'
              components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
              value={locationOptions?.find((option) => option.value === location)}
              onInputChange={handleLocationInputChange}
              onChange={handleLocationFilter}
              options={locationOptions}
              isClearable={true}
              placeholder='Enter Postcode or State'
              styles={{
                menu: (provided) => ({
                  ...provided,
                  display: isInputEmpty ? "none" : "block",
                  borderRadius: 0,
                }),
              }}
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "#fea00199",
                },
              })}
            />
          </FormControl>
        </div>

        <div className='flex-grow sm:col-span-2'>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <Select
              id='numberOfBedRooms'
              value={bedroomOptions.find((option) => option.value === noOfBedrooms)}
              onChange={handleNumberOfBedRooms}
              options={bedroomOptions}
              isClearable={true}
              placeholder='Number of Bedrooms'
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "#fea00199",
                },
              })}
            />
          </FormControl>
        </div>

        {/* category */}
        <div className='flex-grow sm:col-span-2'>
          <FormControl sx={{ m: 1, width: "100%" }}>
            <Select
              id='category'
              value={categoryOptions.find((option) => option.value === category)}
              onChange={handleCategoryFilter}
              options={categoryOptions}
              isClearable={true}
              placeholder='Category'
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "#fea00199",
                },
              })}
            />
          </FormControl>
        </div>
      </div>

      <div className='font-medium text-center'>
        Package Price{" "}
        {propertyFilter?.packagePrice?.max === 10000000 ? (
          <span>(Any)</span>
        ) : (
          <>
            <span>
              {/* AUS dollar string */}
              (${propertyFilter?.packagePrice?.min?.toLocaleString()} - $
              {propertyFilter?.packagePrice?.max?.toLocaleString()})
            </span>{" "}
            <IconButton
              title='clear package price filter'
              onClick={() => {
                dispatch(setPackagePercent([0, 100]))
                dispatch(
                  setPropertyFilter({
                    ...propertyFilter,
                    packagePrice: {
                      min: 0,
                      max: 10000000,
                    },
                  })
                )
              }}
            >
              <ClearIcon />
            </IconButton>
          </>
        )}
      </div>

      <div className='w-4/5 md:w-2/3 max-w-[530px] mx-auto'>
        <Slider
          sx={{
            color: primaryColor,
          }}
          value={packagePercent}
          onChange={handlePackagePrice}
          disableSwap
        />
      </div>
    </div>
  )

  return { propertyFilterJSX }
}
