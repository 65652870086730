import React, { useEffect, useState } from "react"
import Property from "./property"
import { useFetch } from "../../hooks/useFetch"
import { useSelector } from "react-redux"
import { Outlet } from "react-router-dom"
import { queryKey } from "../../constant/queryKey"
import { usePropertyFilter } from "../../component/layout/useLayout/usePropertyFilter"
import StyleWrap from "../style"

const Properties = () => {
  const { key, url } = queryKey.getProperties
  const { propertyFilterJSX } = usePropertyFilter()

  const { data: properties } = useFetch(
    key,
    url,
    () => {},
    () => {},
    false,
    (res) => res?.data
  )

  const propertyFilter = useSelector((state) => state.globalState.propertyFilter)

  const [filteredProperties, setFilteredProperties] = useState([])

  useEffect(() => {
    setFilteredProperties(
      properties
        ?.filter((property) => {
          if (propertyFilter?.location) {
            return property?.suburb?.suburbId === propertyFilter?.location
          } else {
            return true
          }
        })
        ?.filter((property) => {
          if (propertyFilter?.noOfBedrooms) {
            if (propertyFilter?.noOfBedrooms === "4+") {
              return property?.bed === propertyFilter?.noOfBedrooms
            } else {
              return property?.bed === propertyFilter?.noOfBedrooms
            }
          } else {
            return true
          }
        })
        ?.filter((property) => {
          if (propertyFilter?.category) {
            return property?.category === propertyFilter?.category
          } else {
            return true
          }
        })
        ?.filter((property) => {
          if (propertyFilter?.packagePrice) {
            return (
              parseInt(property?.packageprice) >= propertyFilter?.packagePrice?.min &&
              parseInt(property?.packageprice) <= propertyFilter?.packagePrice?.max
            )
          } else {
            return true
          }
        })
    )
  }, [propertyFilter, properties])

  return (
    <StyleWrap>
      <Outlet />
      <div>
        <div className='mx-0 md:mx-2'>
          <div className='text-primary font-bold text-2xl md:text-3xl text-center'>
            It's time to take the next step up the property ladder
          </div>

          <div className='font-medium text-sm md:text-base text-center mt-4 mb-1'>
            <div>
              WebauctN simplifies your investment journey by offering a curated selection
              of pre-built and off the plan properties in high-growth areas, tailored to your
              preferences and investment goals.
            </div>
            <div>
              Benefit from our expertise and access a range of exclusive, handpicked options from
              across Australia designed to match your vision. With no cost to you, our service
              ensures you navigate the market confidently, making informed decisions to secure your
              financial future.
            </div>
          </div>
        </div>

        {propertyFilterJSX}

        {filteredProperties?.length === 0 && (
          <div className='text-center text-lg font-medium mt-4'>No properties found.</div>
        )}

        <div
          className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 
          place-content-stretch justify-items-center sm:justify-items-stretch'
        >
          {filteredProperties?.map((property) => {
            return <Property key={property.propertyid} property={property} />
          })}
        </div>
      </div>
    </StyleWrap>
  )
}

export default Properties
