import { Card, CardContent, CardMedia, Typography } from "@mui/material"
import React from "react"
import SingleBedIcon from "@mui/icons-material/SingleBed"
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined"
import GarageIcon from "@mui/icons-material/Garage"
import { useNavigate } from "react-router-dom"
import areaIcon from "../../images/area-icon.png"

const Property = ({ property }) => {
  const navigate = useNavigate()

  const [hovering, setHovering] = React.useState(false)

  const handlePropertyClick = () => {
    navigate(`/property/${property?.propertyid}`)
  }

  function formatNumberWithCommas(value) {
    if (value && value.length) {
      return " $" + parseInt(value).toLocaleString()
    } else {
      return " Not available"
    }
  }

  return (
    <Card
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className='cursor-pointer h-full w-full'
      onClick={handlePropertyClick}
      // sx={{ maxWidth: 345 }}
    >
      <CardMedia
        component='img'
        alt={property?.propertyid}
        className={`object-cover ${
          hovering ? "scale-105 -translate-y-1" : "scale-100"
        } min-h-[176px] h-44 w-full transition-all`}
        image={property?.imageurl[0]}
      />
      <CardContent sx={{ padding: ".4rem", marginBottom: "4px" }}>
        <Typography variant='h6' component='div' className='flex justify-between items-center'>
          <div>{property?.suburb?.suburb}</div>

          <div className='text-base'>{property?.state} </div>
        </Typography>

        <div className='font-light text-sm pb-2'>{property?.category}</div>

        <div className='flex justify-between items-center'>
          <div className='flex flex-row justify-start items-center gap-3 flex-wrap'>
            <div className='flex'>
              <span className='pr-1'>
                <SingleBedIcon fontSize='small' />
              </span>
              <span className='font-medium text-sm'>{property?.bed}</span>
            </div>

            <div className='-translate-y-[2px] flex items-end'>
              <span className='pr-[3px]'>
                <BathtubOutlinedIcon fontSize='small' />
              </span>
              <span className='font-medium text-sm'>{property?.bath}</span>
            </div>

            <div className='flex'>
              <span className='pr-1'>
                <GarageIcon fontSize='small' />
              </span>
              <span className='font-medium text-sm'>{property?.garage}</span>
            </div>
          </div>

          <div className='font-normal text-sm flex justify-center items-center gap-1'>
            <span>
              <img src={areaIcon} alt='area-icon' className='w-4 h-4' />
            </span>
            <span>
              {property?.landsize} m<sup>2</sup>
            </span>
          </div>
        </div>

        <div className='pt-2 text-sm'>
          Estimated Weekly Rent:{" "}
          <span className='font-medium'>
            {formatNumberWithCommas(property?.estimatedweeklyrental)}
          </span>
        </div>

        <div className='pt-2 text-sm'>
          Package Price:{" "}
          <span className='font-medium'>{formatNumberWithCommas(property?.packageprice)}</span>
        </div>
      </CardContent>
    </Card>
  )
}

export default Property
