import React from "react"
import Routers from "./router/index"
import "./css/fontStyle.css"
import "./App.css"
import { BrowserRouter } from "react-router-dom"

const App = () => {
  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
  )
}
export default App
